import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import { isNull } from 'util';
import SizeMe from 'react-sizeme';
import { CarouselProvider } from 'pure-react-carousel';
import StyledApp from './App.Style';
import { getHomes, getDealer, expireCache } from './services';
import {
  filterHomes,
  getInitialFilters,
  resetStoredfilters,
  setStoredfilters,
} from './components/Listings/Filters';
import Welcome from './components/Welcome/Welcome';
import Listing from './components/Listings/Listing';
import Details from './components/Details/Details';
import NotFound from './components/MIscPages/NotFound';
import { isIe } from './utils';

class App extends Component {
  static propTypes = {
    match: PropTypes.shape({}),
    size: PropTypes.shape({}),
  };

  static defaultProps = {
    match: {},
    size: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      dealer: null,
      homes: null,
      perPage: 6,
      filters: null,
      page: 0,
      sortOption: 'Homes In Stock',
      updateChips: true,
    };
    this.sortHomes = this.sortHomes.bind(this);
  }

  componentDidMount() {
    const { homes, filters } = this.state;
    const { match } = this.props;
    // Fetch data if none exists
    if (match.params.dealerId && (isNull(homes) || isNull(filters))) {
      this.getListingData(match.params.dealerId);
    }
  }

  getListingData = async lotNumber => {
    const { filters } = this.state;
    const dealer = await getDealer(lotNumber);
    const homes = await getHomes(lotNumber);
    let newFilters = JSON.parse(localStorage.getItem('filterValues'));

    if (Array.isArray(homes) && !isNull(dealer)) {
      if (newFilters === null) {
        newFilters = await getInitialFilters(homes, filters);
      }

      this.setState({
        dealer,
        homes,
        filters: newFilters,
      });
    }
  };

  refreshCache = () => {
    const { dealer } = this.state;
    expireCache('dealerInfo');
    expireCache('dealerHomes');
    if (dealer) {
      this.getListingData(dealer.dealerId).then(() => {
        this.resetFilters();
      });
    }
  };

  resetFilters = async () => {
    const { homes, filters } = this.state;
    const newFilters = await getInitialFilters(homes, filters);
    this.setState({
      filters: newFilters,
    });
    resetStoredfilters();
  };

  sortChange = e => {
    this.setState({
      page: 0,
      sortOption: e.target.value,
    });
  };

  filterChange = (filter, value) => {
    const { filters, updateChips } = this.state;
    filters[`${filter}`] = value;
    this.setState({
      filters,
      page: 0,
      updateChips: !updateChips,
    });
    setStoredfilters(filters);
  };

  setPage = page => {
    this.setState({
      page,
    });
  };

  sortHomes(homes) {
    const { sortOption } = this.state;

    const tempHomes = homes;
    switch (sortOption) {
      case 'Price (High to Low)':
        return tempHomes.sort(
          (prev, current) => current.priceSort - prev.priceSort
        );
      case 'Price (Low to High)':
        return tempHomes.sort(
          (prev, current) => prev.priceSort - current.priceSort
        );
      case 'Square Feet':
        return tempHomes.sort(
          (prev, current) => prev.squareFeet - current.squareFeet
        );
      case 'Building Facility A-Z':
        return tempHomes.sort(
          (prev, current) => prev.homeBuilder - current.homeBuilder
        );
      default:
        return homes;
    }
  }

  render() {
    const {
      filters,
      homes,
      dealer,
      perPage,
      page,
      sortOption,
      updateChips,
    } = this.state;
    const { match, size } = this.props;

    let filteredHomes = filterHomes(homes, filters);
    if (sortOption !== 'Homes In Stock') {
      filteredHomes = this.sortHomes(filteredHomes);
    }
    const pages = filteredHomes ? Math.ceil(filteredHomes.length / 6) : 1;

    return (
      <StyledApp>
        {match && (
          <Switch>
            <Route
              name="listing"
              path={`${match.url}/listing`}
              render={props => (
                <CarouselProvider // TODO set this dynamically based on width and height
                  naturalSlideWidth={1}
                  naturalSlideHeight={0.6}
                  totalSlides={pages || 1}
                  visibleSlides={1}
                  step={1}
                  currentSlide={page}
                  dragEnabled={!isIe} // Disbale dragging for IE/Edge, it's not pretty
                >
                  <Listing
                    {...props}
                    width={size.width}
                    filters={filters}
                    filterChange={this.filterChange}
                    sortChange={this.sortChange}
                    homes={filteredHomes}
                    page={page}
                    pages={pages}
                    setPage={this.setPage}
                    perPage={perPage}
                    dealer={dealer}
                    resetFilters={this.resetFilters}
                    updateChips={updateChips}
                    refreshCache={this.refreshCache}
                  />
                </CarouselProvider>
              )}
            />
            <Route
              name="details"
              path={`${match.url}/Details/:homeId`}
              render={props => {
                const currentHome = homes
                  ? homes.filter(
                      home => home.id === props.match.params.homeId
                    )[0]
                  : null;

                return (
                  <Details {...props} dealer={dealer} home={currentHome} />
                );
              }}
            />
            <Route
              name="welcome"
              path="/"
              render={() => <Welcome dealer={dealer} />}
            />
            <Route component={NotFound} />
          </Switch>
        )}
      </StyledApp>
    );
  }
}

export default SizeMe({ monitorHeight: true })(App);
